@import "../../variables";

.sidebar-collapsed {
  .sidebar {
    width: 50px;

    .logo {
      padding: 0px;
      padding-left: 8px;
      font-size: 27px;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 20px;

      span,
      img,
      h2,
      .welcome {
        display: none;
      }
    }

    .version,
    .MuiAccordionSummary-expandIconWrapper,
    .MuiAccordionSummary-root span,
    .MuiAccordionDetails-root {
      display: none;
    }

    .hover {
      .MuiAccordionDetails-root {
        display: block;
        position: absolute;
        top: 0px;
        left: 49px;
        width: 150px;
        background-color: $primary;
      }

      .MuiAccordion-region {
        visibility: initial;
      }
    }

    .logout {
      right: auto;
      left: 3px;
    }

    .sidebar-wrapper,
    .sidebar-scroll {
      position: initial;
    }

    .collapse {
      position: absolute;
      min-width: 140px;
      left: 49px;
      background: #253053;
    }

    &.dev {
      .logo::after {
        content: "Dev";
      }
    }

    &.ppd {
      .logo::after {
        content: "Ppd";
      }
    }

    &.prd {
      .logo::after {
        content: "Prd";
      }
    }
  }

  .main-panel {
    width: calc(100% - 50px);
  }
}

.sidebar {
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 260px;
  left: 0;
  z-index: 1030;
  border-right: 1px solid #ddd;
  background-color: $primary;
  color: hsla(0, 0%, 100%, 0.65);

  .logo {
    text-align: center;
    min-height: 156px;


    img {
      width: 90%;
      margin-top: 15px;

      &.logo-icone:hover {
        cursor: pointer;
        animation: rotation 3s infinite cubic-bezier(0.66, 0, 0.43, 1.06);
      }
    }
  }

  .welcome {
    display: flex;
    padding: 15px;
    justify-content: center;
  }

  .MuiAccordion-root {
    background: $primary;
    color: hsla(0, 0%, 100%, 0.65);
    border-top: 1px solid darken($primary, 5);
    border-bottom: 1px solid darken($primary, 5);
    border-left: 0px;
    border-right: 0px;
    border-radius: 0 !important;
    box-shadow: none;
    margin-bottom: -1px;

    &:hover {
      background-color: darken($primary, 5);
    }

    a {
      text-decoration: none;
    }

    .MuiAccordionSummary-content>a {
      display: flex;
      flex: 1 1;
      align-items: center;
      margin: -15px;
      padding: 15px;
    }

    a,
    svg {
      color: hsla(0, 0%, 100%, 0.65);
    }

    div.Mui-expanded.MuiAccordionSummary-expandIconWrapper {
      transform: rotate(90deg);
    }

    .MuiAccordionSummary-root:hover,
    .MuiAccordionSummary-root.active {
      color: white;

      a,
      svg {
        color: white;
      }
    }

    .MuiAccordionDetails-root {

      a:hover,
      .active a {
        color: white;
        font-weight: 600;
      }
    }

    .MuiAccordionSummary-content {
      svg {
        margin-right: 10px;
        margin-top: 1px;
      }
    }
  }

  #burger {
    position: absolute;
    right: -45px;
    top: 5px;

    button {
      background: $primary;
    }
  }

  button {
    background: $primary;
    color: white;

    &:hover {
      background: lighten($primary, 10);
    }
  }

  display: flex;
  flex-direction: column;

  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 170px);
    overflow: auto;
    width: 100%;
    z-index: 4;

    /* width */
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #4a5472;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #f8f9fa;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #ffffffa6;
    }

    .sidebar-scroll {
      height: 100%;
      overflow: hidden visible;
      scrollbar-width: thin;
    }
  }

  .logout {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 5;

    .btn {
      background: #fff;
      color: $primary;
    }
  }

  .logo {
    position: relative;
    padding: 7px 0.7rem;
    z-index: 4;
  }

  .logo span {
    font-size: 26px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: 15px;
  }

  .menu {
    .btn {
      text-align: left;
    }

    a {
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;
      color: hsla(0, 0%, 100%, 0.65);
      text-decoration: none;
    }

    button {
      width: 100%;
    }

    .icon {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .version {
    color: #888;
    font-size: 12px;
    position: absolute;
    bottom: 15px;
    left: 10px;
  }

  &.dev,
  &.ppd,
  &.prd {
    .logo::after {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0 10px;
      font-size: 12px;
    }
  }

  &.dev {
    .logo::after {
      content: "Développement";
      background: rgba(255, 255, 255, 0.5);
      color: rgba(255, 255, 255, 0.65);
    }
  }

  &.ppd {
    .logo::after {
      content: "Préprod";
      background: rgba(255, 177, 0, 1);
      color: #253053;
    }
  }

  &.prd {
    .logo::after {
      content: "Production";
      background: rgb(243, 0, 0);
      color: white;
    }
  }

  .dry-run {
    position: absolute;
    font-size: 12px
  }
}