.chart-header{
    border-bottom: 1px solid #CCC;
    padding-bottom: 15px;
    padding-top: 15px; 
    font-weight: bold;
    margin:0px;
}

.chart-row{
    border-bottom: 1px solid #CCC;
    padding-bottom: 15px;
    padding-top: 15px;
    margin:0px;
}

.chart-legend{
    display: flex;
    height: calc(100% - 110px);
    align-items: center;

    .legend-container{
        margin-bottom:10px;

        .legend-color{
            width: 40px; 
            height: 15px; 
            display: inline-block;
            margin-right: 15px;
        }
    }

    .goToDetails{
        margin-bottom:40px;
    }
}